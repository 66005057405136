import styled from "styled-components";
import { Link } from 'react-router-dom';
import swiss from "../../../images/swiss.jpg";
import chiemgau from "../../../images/chiemgau.jpg";
import immo1 from "../../../images/immobilien/immo1.jpg";
import immo2 from "../../../images/immobilien/immo2.jpg";
import immo3 from "../../../images/immobilien/immo3.jpg";
import wackersberg from "../../../images/immobilien/wackersberg.webp";
import rohbau from "../../../images/immobilien/rohbau.webp";
import sacherl from "../../../images/immobilien/sacherl.webp";
import benediktbeuern from "../../../images/immobilien/benediktbeuern.webp";
import sudfrankreich from "../../../images/immobilien/sudfrankreich.webp";
import laax from "../../../images/immobilien/laax.webp";
import tirol from "../../../images/immobilien/Tirol_aussen.jpeg";
// import tirol from "../../../images/immobilien/Tirol_aussen.jpeg";
import StyledLink from "../../atoms/StyledLink";


const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const Überschrift = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${chiemgau});
    background-repeat: repeat;
    background-color: #cccccc;
    background-size: cover;
    height: 650px;
    width: 100%;
`;

const ÜberUns = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 200px 0;
    background-color: #EBEBEB;
    color: black;
`;


const Immobilien = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    width: 100%;
`;

const ImmoContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 80px;
`;

const Header1 = styled.h1`
    font-size: 70px;
    font-weidht:400;
    letter-spacing: 10px;
`;

const Header2 = styled.h2`
    margin-bottom: 30px;
    font-size: 45px;
`;

const Text = styled.p`
    font-size: 24px;
`;

const ParDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
`;

const ImmoImage = styled.img`
    width: 280px;
`;

const AlleImmos = styled(Link)`
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 80px;
    padding: 5px;

    &:hover{
        background-color: #f2f2f2
        ;
    }
`;

const Anchor = styled.a`
    padding: 0;
    margin: 0;
`;

function StartPage() {
    return(
        <Content>
            <Überschrift>
                <Header1>Ihr Makler in Oberbayern</Header1>
            </Überschrift>
            <ÜberUns>
                <ParDiv>
                    {/* <Header2>Immobilien sind unsere Leidenschaft</Header2> */}
                    <Text>Ob Sie eine Immobilie suchen oder verkaufen wollen, wir stehen Ihnen im kompletten Prozess von Beginn an bis zum Eigentumsübergang zur Seite. Mit über 15 Jahren Erfahrung in Sachen Immobilien in den begehrtesten Gegenden Oberbayerns und darüber hinaus, bringt unser unabhängiges Familienunternehmen Eigentumssuchende und Verkäufer zusammen. Wir freuen uns, auch Ihr verlässlicher Partner zu werden und Sie als Immobilienmakler Ihres Vertrauens bei Ihrem Vorhaben zu begleiten.</Text>
                    <br/>
                    {/* <Text>Zuverlässigkeit, Kreativität, Professionalität und Diskretion sind unsere Stärken, um sich im ständigen Wandel erfolgreich zu positionieren. Der Einsatz von modernster digitaler Film und IT-Technik auf hohem technischen Niveau eröffnet ein beinahe realistisches Bild Ihrer Immobilie und zugleich den Blick auf das besondere Potential. </Text>
                    <br/>    */}
                    {/* <Text>Von unschätzbarem Wert ist hierbei die Zusammenarbeit mit einem ehrgeizigen Team von Designern, Fotografen, Architekten, Textern, Drohnen-Piloten und Programmierern.</Text>  */}
                    <br/><br/>
                    <Text>Wir freuen uns von ihnen zu hören!</Text>
                    {/* <Text>Ob sie eine Immobilie suchen oder verkaufen wollen, wir stehen ihnen im kompletten Prozess von Beginn an bis zum Notartermin zur Seite. Mit 14 Jahren Erfahrung in Sachen Immobilien in den begehrtesten Gegenden Oberbayerns und darüber hinaus, helfen wir ihnen bei Verhandlungen zu ihren Gunsten.</Text> */}
                    {/* <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam</Text> */}
                </ParDiv>
            </ÜberUns>
            <Immobilien>
                <Header2>Unsere Immobilien</Header2>
                <ImmoContent>
                    <Anchor href="https://frankimmo.com/immobilie1" target="_blank" rel="noreferrer">
                        <ImmoImage src={wackersberg} />
                    </Anchor>
                    <Anchor href="https://frankimmo.com/immobilie2" target="_blank" rel="noreferrer">
                        <ImmoImage src={rohbau} />
                    </Anchor>
                    <Anchor href="https://frankimmo.com/immobilie3" target="_blank" rel="noreferrer">
                        <ImmoImage src={sacherl} />
                    </Anchor>
                </ImmoContent>
                <ImmoContent>
                    <Anchor href="https://frankimmo.com/immobilie4" target="_blank" rel="noreferrer">
                        <ImmoImage src={benediktbeuern} />
                    </Anchor>
                    {/* <Anchor href="https://frankimmo.com/immobilie5" target="_blank" rel="noreferrer">
                        <ImmoImage src={sudfrankreich} />
                    </Anchor> */}
                    <Anchor href="https://frankimmo.com/immobilie6" target="_blank" rel="noreferrer">
                        <ImmoImage src={laax} />
                    </Anchor>
                </ImmoContent>
                {/* <AlleImmos onClick={() => {window.location.href="/immobilien"}}>Alle Immobilien</AlleImmos> */}
                <StyledLink destination={"/immobilien"} content={"Alle Immobilien"}/>
            </Immobilien>
        </Content>
    )
}

export default StartPage;