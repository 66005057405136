import styled from "styled-components";
import Content from "../Content";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 0 15px;
    color: black;
`;

const Background = styled.div`
    display: flex;
    justify-content: center;
    // background-color: #ba2d1a;
    background-color: #f5f5f5;
    width: 100%;
    height: 400px;
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    // border: 3px solid red;

`;

const Header2 = styled.h2`
    margin-bottom: 30px;
    font-size: 35px;
    // color: white;
`;

const StyledP = styled.p`
    margin: 10px 0;
    // color: white;
`;

const Weiteres = styled.div`
    margin-top: 40px;
`;

function Footer() {
    return (
        <Background>
            <Div>
                <Header2>Frank Immobilien</Header2>
                <StyledP>Petra Frank</StyledP>
                <StyledP><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;&nbsp;+49 8851 9400434</StyledP>
                <StyledP><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;info@frank-immobilien.net</StyledP>
                <StyledP><FontAwesomeIcon icon={faLocationDot} />&nbsp;&nbsp;&nbsp;Rothenberg Nord 5, 82431 Kochel am See</StyledP>
                {/* <>Datenschutzerklärung</> */}
                <Weiteres>
                    <StyledLink onClick={() => {window.location.href="/datenschutz"}}>Datenschutzerklärung</StyledLink>
                    <StyledLink onClick={() => {window.location.href="/impressum"}}>Impressum</StyledLink>
                    <StyledLink onClick={() => {window.location.href="/widerruf"}}>Widerruf</StyledLink>
                </Weiteres>
            </Div>
        </Background>
    )
}

export default Footer;