import styled from "styled-components";
import { Link } from 'react-router-dom';
import frankimmo from "../../../images/LOGO.jpeg";
// import frankimmo-logo from "../../../images/frankimmo-logo.png";

const Background = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #f5f5f5;
    width: 100%;
    height: 100px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 90%;
`;

const Interaction = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
`;


const StyledButton = styled.button`
    color: black;
    text-decoration: none;
    margin: 0 15px;

    @media (max-width: 430px) {
        margin: 0 5px;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 0 15px;
    color: black;
`;

const Logo = styled.img`
    width: 100px;
`;

function Header() {
    function handleScroll() {
        window.scroll({ bottom: 0, left: 0,  behavior: 'smooth' });
      }
    return (
        <Background>
            <Content>
                <StyledLink onClick={() => {window.location.href="/"}}><Logo src={frankimmo} alt="frankimmo" /></StyledLink>
                <Interaction>
                    {/* <StyledLink  onClick={() => {window.location.href="/about"}}>Über Uns</StyledLink> */}
                    <StyledLink onClick={() => {window.location.href="/immobilien"}}>Immobilien</StyledLink>
                    {/* <StyledLink onClick={handleScroll}>Kontakt</StyledLink> */}
                </Interaction>
            </Content>
        </Background>
    )
}
 
export default Header;