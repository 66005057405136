import styled from "styled-components";

const Div = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`;


function Content({children}) {
    return(
        <Div>{children}</Div>
    )
}

export default Content;