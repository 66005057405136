import styled from "styled-components";

const Div = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Content = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const Header = styled.h1`
    margin: 30px 0;
`;

function Widerruf() {
    return (
        <Div>
            <Content>
            <Header>I M P R E S S U N G</Header>
            <b>Widerrufsbelehrung für Verbraucher</b><br/><br/>



(1) Widerrufsrecht<br/>
Schließen Sie den Vertrag mit uns als Verbraucher, haben Sie das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br/><br/>

Um das Widerrufsrecht auszuüben, müssen Sie uns:<br/><br/>

frank immobilien <br/>
Bockschützstraße 16 <br/>
83646 Bad Tölz, <br/>
Fon +49 (0) 8041 793 2120, <br/>
Mobil +49 (0) 176 55851133<br/>
Mail: kontakt@frankimmobilien.de<br/>
Web: www.frankimmo.de<br/><br/>

mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, den Vertrag zu widerrufen, informieren. Sie können dafür das nachstehende Musterwiderrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.<br/><br/>

(2) Folgen des Widerrufs<br/>
Wenn Sie diesen Vertrag widerrufen, hat frank immobilien Ihnen alle Zahlungen, die frank immobilien von Ihnen erhalten hat, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei frank immobilien eingegangen ist. Für diese Rückzahlung verwendet frank immobilien dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, es wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br/><br/>

Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie frank immobilien einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie frank immobilien von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.<br/><br/>

- ENDE DER WIDERRUFSBELEHRUNG –<br/><br/>

frank immobilien <br/>
Web: www.frankimmobilien.de <br/>
Mobil +49 (0) 176 55851133<br/><br/><br/><br/>


<b>Muster - Widerrufsformular</b><br/><br/>

Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.<br/><br/>

An<br/>
frank immobilien <br/>
Bockschützstraße 16<br/>
83646 Bad Tölz<br/>
E-Mail info@frank immobilien.de<br/><br/>

Hiermit widerrufe(n) ich/wir(*) den von mir/uns(*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistungen(*):<br/><br/>
Bestellt am (*), erhalten am (*):<br/>

[.........................................................................] Name des/der Verbraucher(s)<br/>

[.........................................................................] Anschrift des/der Verbraucher(s)<br/>

[.........................................................................] Unterschrift des/der Verbraucher(s), nur bei Mitteilung auf Papier<br/>

[.........................................................................] Ort, Datum<br/><br/>

* Unzutreffendes bitte streichen.<br/><br/>

<b>Belehrung gem. § 356 Abs. 4 BGB</b><br/>
Hiermit bestätigt der Auftraggeber, davon Kenntnis genommen zu haben, dass ihm oben genanntes Widerrufsrecht für den oben abgeschlossenen Maklervertrag erlischt, wenn frank immobilien ihm die vollständigen Unterlagen übergeben hat und eine Besichtigung stattfand, und der Auftraggeber sich nur noch zu entscheiden hat, ob er den nachgewiesenen/vermittelten Hauptvertrag abschließt, ohne dass es weiterer Tätigkeiten von frank immobilien bedarf. Auf § 356 Abs. 4 BGB wurde der Auftraggeber hingewiesen.<br/><br/>



            </Content>
        </Div>
    )
}

export default Widerruf;