import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import rohbau from "../../../../images/immobilien/rohbau.webp";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageEins() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={rohbau} />
                <DataBox>
                    <BlockBox>
                        <H3s>1.620.000 €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    <BlockBox>
                        <H3>82377 Penzberg</H3>
                        <p>Adresse</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>10</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>216 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>813 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox>
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Rohbau auf großzügigem Grundstück</Header1>
                <HR />
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        <p>Etagenanzahl:</p>
                        <p>Wohnfläche ca:</p>
                        <p>Nutzfläche ca.:</p>
                        {/* <p>Grundstück ca.:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Einfamilienhaus (freistehend)</p>
                        <p>4</p>
                        <p>216 m²</p>
                        <p>168 m²</p>
                        {/* <p>1.366 m²</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Grundstück ca.:</p>
                        <p>Zimmer:</p>
                        {/* <p>Schlafzimmer :</p> */}
                        <p>Badezimmer :</p>
                        <p>Garage/ Stellplatz :</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>813 m²</p>
                        <p>10</p>
                        <p>4</p>
                        <p>2</p>
                        {/* <p>2 Garagen</p> */}
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Baujahr:</p>
                        <p>Bauphase:</p>
                        {/* <p>Objektzustand:</p>
                        <p>Heizungsart:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>1974</p>
                        <p>Haus im Bau</p>
                        {/* <p>Renovierungsbedürftig</p>
                        <p>Öl-Heizung</p> */}
                    </AlignBoxItem>
                    {/* <AlignBoxItem>
                        <p>Wesentliche Energieträger:</p>
                        <p>Energieausweis:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Öl</p>
                        <p>liegt zur Besichtigung vor</p>
                    </AlignBoxItem> */}
                </AlignBox>
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Südlich von München auf einem ca. 813 qm großen Grundstück liegt dieser zeitlos geplante, großzügige Rohbau mit insgesamt ca. 216 qm Wohnfläche. Durch einen Dachausbau würde man weitere ca. 60 qm  Wohnfläche generieren können. Die perfekte Ausrichtung nach Süden und Westen sichert ein Maximum an Licht. <br/><br/>

Dieses einmalige Objekt befindet sich in einem ruhigen beschaulichen Wohnquartier und man ist dennoch nicht weit vom Penzberger Zentrum mit allen Annehmlichkeiten einer lebendigen Kleinstadt entfernt. Sinnvoll geplant, solide errichtet und in großflächiger Bauweise empfiehlt sich dieser Rohbau für alle, die im Grünen wohnen wollen, mit hohem Freizeitwert in direkter Umgebung - und dabei einen unkomplizierten und schnellen Anschluss an die Metropole München benötigen und gleichzeitig hohe Ansprüche an Ihr neues Zuhause stellen.<br/><br/>
Das Erdgeschoss sowie das Obergeschoss beherbergen jeweils auf einer Ebene eine komplette Wohnung mit 2 - 4 großen Zimmern. Ingesamt sind ca. 5 - 10 Zimmer je nach Wunschgröße möglich.<br/><br/>

Das Haus ist komplett unterkellert. Bedingt durch die hohe Raumhöhe sowie Tageslichteinfall hat man hier Souterrain Qualität. Ideale Bedingung für Arbeits- und Hobbyräume sowie Lagerbereiche. Es gibt hier auch einen direkten Zugang über eine weitere Aussen-Eingangstür. Insgesamt ist dieses Objekt äußerst attraktiv auch für Anleger, die sich auf hohe Nachfrage und Wertsteigerung mit solider Rendite freuen dürfen.<br/><br/>

Das Haus wird im Ist-Zustand als Rohbau verkauft.. Es bietet ein hohes Potential und lässt viele Gestaltungsmöglichkeiten offen.<br/><br/>

Für eine größere Projektierung ist ein optionaler Kauf des Nachbargrundstücks mit 895 qm möglich.</p>

            <HR/>
            <h2>Ausstattung</h2>
            <p>Großzügige Architektur, Südlage<br/>
            - Wohnfläche und Nutzfläche  ca. 384 qm<br/>
            - reine Gesamtwohnfläche ca. 216 qm, <br/>
            - Grundstücksfläche ca. 813 qm<br/>
            
            - hochwertige YTONG (Porenbeton) Bauweise<br/>
            - 10 Zimmer; 4 Bäder, in jedem Stockwerk jeweils zwei<br/>
            - Baustart war 1974, wurde danach nicht mehr fertiggestellt<br/>
            - genehmigte Doppel-Garage ist noch nicht vorhanden<br/>
            - großzügige Fensterfronten sollten erneuert werden <br/><br/>
            
            - Es besteht die Möglichkeit zur Fertigstellung oder Neubau <br/><br/>
            
            - Der Innenausbau ist nach eigenen Vorstellungen realisierbar<br/>
            - Im OG und DG traumhafter Bergblick bis zur Zugspitze<br/>
            - perfekter Anschluss an die charmante und pulsierende Metropole München<br/>
            - Bahnanschluss nur ca. 2 km vom Haus entfernt<br/>
            - ein Paradies für Naturliebhaber und sportlich Aktive
            </p>

            <HR/>
            <h2>Lage</h2>
            <p>Das Anwesen liegt in der begehrten und äußerst ruhigen und entspannten Wohngegend in Penzberg/Hochfeld nicht weit vom Zentrum mit allen Annehmlichkeiten einer lebendigen Kleinstadt, die keine Wünsche offen lässt - mitten im bayrischen Alpenvorland. Ein guter Ausgangspunkt für Freizeit- und Kulturaktivitäten in die umliegende Urlaubsregion.<br/><br/>

Fußläufig sind Einkaufsmöglichkeiten zu erreichen. 
Von der Grundschule bis hin zum Gymnasium sind alle staatlichen Bildungseinrichtungen vorhanden. Zudem befinden sich in Penzberg eine Montessori- und eine Musikschule sowie eine VHS und eine Stadtbücherei. Die Stadt verfügt über eine vielfältig ausgebaute Kinderbetreuung.<br/>
Auch gibt es in Penzberg ein Klinikum und viele Ärzte aus fast allen möglichen Fachbereichen. 
Durch die gute Anbindung an München und das hier ansässige, bekannte Pharma & Diagnostics Unternehmen Roche ist Penzberg ein begehrter Wohnort. Das sehenswerte Campendonk Museum sollte man sich ebenfalls nicht entgehen lassen. Im Nachbarort befindet sich das Kloster Benediktbeuern, welches mit unzähligen kulturellen Veranstaltungen z.B. Konzert Events und einer Ausfluggaststätte mit herrlichem Kloster-Biergarten aufwartet. Gut markierte Wander- und Radwanderwege gibt es in Hülle und Fülle. Für Sportler bieten sich die Hausberge von Kochel, der Blomberg von Bad Tölz zum Wandern, das Brauneck mit Klettergarten oder die Isar zum Baden und jede Menge weiterer Outdoor Sportarten an.<br/><br/>

Lassen Sie sich begeistern bei einer Besichtigung.<br/><br/>

Willkommen im bayrischen Oberland. <br/>

Einziehen und genießen. Willkommen daheim!</p>
            </InhaltBox>


            {/* <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   

Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   

Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer</Text> */}
        </Content>
        </>
    )
}

export default ImmoPageEins;