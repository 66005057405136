import styled from "styled-components";
import Content from "../../molecules/Content";

const Background = styled.div`
    display: flex;
    justify-content: center;
    height: 800px;
    width: 100%;
`;

function AboutPage() {
    return (
        <Background>
            <Content>
                About page
            </Content>
        </Background>
    )
}

export default AboutPage;