import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import benediktbeuern from "../../../../images/immobilien/benediktbeuern.webp";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageEins() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={benediktbeuern} />
                <DataBox>
                    <BlockBox>
                        <H3s>1.110.000 €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    <BlockBox>
                        <H3>740 m²</H3>
                        <p>Fläche</p>
                    </BlockBox>
                    {/* <BlockBox>
                        <H3>10</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>216 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>813 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox> */}
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Grundstück mit Bergblick in Benediktbeuern</Header1>
                
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Das Grundstück befindet sich in bester Lage von Benediktbeuern mit sensationellem Blick auf die Benediktenwand. <br/><br/>

Es bietet Ihnen vielfältige Möglichkeiten für eine individuelle, natürliche Lebensweise im wunderschönen Alpenvorland.<br/><br/>

Denkbar wäre die Nutzung mit einem kleinen Einfamilienhaus, Bungalow, Ferienhaus, als Freizeitgrundstück  oder auch als Kapitalanlage..<br/><br/>

Auf dem Grundstück befindet sich ein wunderschöner, alt eingewachsener Garten.<br/><br/>

Für weitere Informationen bitten wir Sie um Ihre vollständigen Kontaktdaten einschließlich Rufnummer.</p>

            {/* <HR/>
            <h2>Ausstattung</h2>
            <p>Großzügige Architektur, Südlage<br/>
            - Wohnfläche und Nutzfläche  ca. 384 qm<br/>
            - reine Gesamtwohnfläche ca. 216 qm, <br/>
            - Grundstücksfläche ca. 813 qm<br/>
            
            - hochwertige YTONG (Porenbeton) Bauweise<br/>
            - 10 Zimmer; 4 Bäder, in jedem Stockwerk jeweils zwei<br/>
            - Baustart war 1974, wurde danach nicht mehr fertiggestellt<br/>
            - genehmigte Doppel-Garage ist noch nicht vorhanden<br/>
            - großzügige Fensterfronten sollten erneuert werden <br/><br/>
            
            - Es besteht die Möglichkeit zur Fertigstellung oder Neubau <br/><br/>
            
            - Der Innenausbau ist nach eigenen Vorstellungen realisierbar<br/>
            - Im OG und DG traumhafter Bergblick bis zur Zugspitze<br/>
            - perfekter Anschluss an die charmante und pulsierende Metropole München<br/>
            - Bahnanschluss nur ca. 2 km vom Haus entfernt<br/>
            - ein Paradies für Naturliebhaber und sportlich Aktive
            </p> */}

            <HR/>
            <h2>Lage</h2>
            <p>Ruhige Ortsrandlage mit sensationellem Bergblick. Zwei Schwimmbäder, der Bahnanschluss, das Kloster mit einem Bio-Laden sowie im Ortskern diverse Einkaufsmöglichkeiten lassen keine Wünsche offen. <br/>
Dieses Grundstück ist ein Rastplatz für die Seele und ein Paradies für Naturliebhaber und sportlich Aktive.<br/><br/>
 
Zum Kochelsee sind es ca. 8 Autominuten<br/><br/>
 
Herrliche idyllische Natur-Lage nahe den Bergen des bayerischen Oberlandes<br/><br/>
  
Guter Anschluss an die charmante und pulsierende Metropole München</p>
            </InhaltBox>


            {/* <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   

Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   

Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer</Text> */}
        </Content>
        </>
    )
}

export default ImmoPageEins;