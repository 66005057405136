import styled from "styled-components";
import immo1 from "../../../images/immobilien/immo1.jpg";
import immo2 from "../../../images/immobilien/immo2.jpg";
import immo3 from "../../../images/immobilien/immo3.jpg";
import wackersberg from "../../../images/immobilien/wackersberg.webp";
import wackersberg2 from "../../../images/immobilien/wackersberg2.webp";
import rohbau from "../../../images/immobilien/rohbau.webp";
import sacherl from "../../../images/immobilien/sacherl.webp";
import benediktbeuern from "../../../images/immobilien/benediktbeuern.webp";
import sudfrankreich from "../../../images/immobilien/sudfrankreich.webp";
import laax from "../../../images/immobilien/laax.webp";
import innsbruck from "../../../images/immobilien/innsbruck.webp";
import tirol from "../../../images/immobilien/Tirol_aussen.jpeg";
import StyledLink from "../../atoms/StyledLink";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
`;

const Immobilie = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // border-bottom: 0.3px solid black;
    padding: 80px 0;
`;

const Image = styled.img`
    width: 400px;
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 200px;
`;

const Text = styled.p`
    font-size: 20px;
    margin-bottom: 40px;
`;

const Header2 = styled.h2`
    margin-bottom: 30px;
`;

const Anchor = styled.a`
    padding: 0;
    margin: 0;
`;

function AlleImmosPage() {
    return(
        <Content>
            <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie1" target="_blank" rel="noreferrer">
                    <Image src={wackersberg2} />
                </Anchor>
                <TextDiv>
                    <Header2>Einfamilienhaus in Wackersberg</Header2>
                    <Text>Seltene Gelegenheit! Charmantes Einfamilienhaus mit Esprit!</Text>
                    <div>
                        <StyledLink destination={"/immobilie1"} content={"Details"}/>
                    </div>
                </TextDiv>
                    {/* <hr/> */}
            </Immobilie>
            <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie2" target="_blank" rel="noreferrer">
                    <Image src={rohbau} />
                </Anchor>
                <TextDiv>
                    <Header2>Rohbau in Penzberg</Header2>
                    <Text>Zeitlos geplanter Rohbau auf großzügigem Grundstück mit insgesamt ca. 216 qm Wohnfläche</Text>
                    <div>
                        <StyledLink destination={"/immobilie2"} content={"Details"}/>
                    </div>
                </TextDiv>
            </Immobilie>
            <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie3" target="_blank" rel="noreferrer">
                    <Image src={sacherl} />
                </Anchor>
                <TextDiv>
                    <Header2>Kleines Sacherl in Benediktbeuern</Header2>
                    <Text>Kleines Sacherl mit viel Potenzial in herrlicher idyllischer Natur-Lage nahe den Bergen des bayerischen Oberlandes</Text>
                    <div>
                        <StyledLink destination={"/immobilie3"} content={"Details"}/>
                    </div>
                </TextDiv>
            </Immobilie>
            <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie4" target="_blank" rel="noreferrer">
                    <Image src={benediktbeuern} />
                </Anchor>
                <TextDiv>
                    <Header2>Grundstück in Benediktbeuern</Header2>
                    <Text>Grundstück mit sensationellem Blick auf die Benediktenwand</Text>
                    <div>
                        <StyledLink destination={"/immobilie4"} content={"Details"}/>
                    </div>
                </TextDiv>
            </Immobilie>
            {/* <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie5" target="_blank" rel="noreferrer">
                    <Image src={sudfrankreich} />
                </Anchor>
                <TextDiv>
                    <Header2>Schloss in Südfrankreich</Header2>
                    <Text>Charmantes Schloss in Südfrankreich mit 15 Zimmern und 38.793 m² Grundstück</Text>
                    <div>
                        <StyledLink destination={"/immobilie5"} content={"Details"}/>
                    </div>
                </TextDiv>
                    {/* <hr/> */}
            {/* </Immobilie>  */}
            <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie6" target="_blank" rel="noreferrer">
                    <Image src={laax} />
                </Anchor>
                <TextDiv>
                    <Header2>Ferienwohnung in Laax (Schweiz)</Header2>
                    <Text>1,5 Zimmer Ferienwohnung mit Berg- und Seeblick, 2 Bädern und Sauna</Text>
                    <div>
                        <StyledLink destination={"/immobilie6"} content={"Details"}/>
                    </div>
                </TextDiv>
                    {/* <hr/> */}
            </Immobilie>
            {/* <Immobilie>
                <Anchor href="https://frankimmo.com/immobilie7" target="_blank" rel="noreferrer">
                    <Image src={tirol} />
                </Anchor>
                <TextDiv>
                    <Header2>Doppelhaushälfte in Tirol</Header2>
                    <Text>Doppelhaushälfte mit spektakulärer Dachterrasse in Tirol, Österreich</Text>
                    <div>
                        <StyledLink destination={"/immobilie7"} content={"Details"}/>
                    </div>
                </TextDiv>
                    {/* <hr/> */}
            {/* </Immobilie> */}
        </Content>
    )
}

export default AlleImmosPage;