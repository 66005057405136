import styled from "styled-components";

const Div = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Content = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const Header = styled.h1`
    margin: 30px 0;
`;

function Datenschutz() {
    return (
        <Div>
            <Content>
            <Header>D A T E N S C H U T Z E R K L Ä R U N G</Header>
Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat
einen besonders hohen Stellenwert für die Geschäftsleitung für folgenden
Herausgeber dieser Website: frank immobilien. Eine Nutzung der Internetseiten der
frank immobilien ist grundsätzlich ohne jede Angabe personenbezogener Daten
möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens
über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine
Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung
personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung
keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen
Person ein.<br/><br/>
Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der
Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt
stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit
den für die frank immobilien geltenden landesspezifischen
Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser
Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen,
genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden
betroffene Personen mittels dieser Datenschutzerklärung über die ihnen
zustehenden Rechte aufgeklärt.<br/><br/>
Die frank immobilien hat als für die Verarbeitung Verantwortlicher zahlreiche
technische und organisatorische Maßnahmen umgesetzt, um einen möglichst
lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen
Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen
grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei,
personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch,
an uns zu übermitteln.<br/><br/>
1. Begriffsbestimmungen<br/>
Die Datenschutzerklärung der frank immobilien beruht auf den Begrifflichkeiten, die
durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Unsere
Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden
und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.<br/><br/>
Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:<br/><br/>
1) Personenbezogene Daten<br/>
Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
identifizierbare natürliche Person (im Folgenden „betroffene Person") beziehen. Als
identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
natürlichen Person sind, identifiziert werden kann.<br/><br/>
2) Betroffene Person<br/>
Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet
werden.<br/><br/>
3) Verarbeitung<br/>
Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte
Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das
Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
Einschränkung, das Löschen oder die Vernichtung.<br/><br/>
4) Einschränkung der Verarbeitung<br/>
Einschränkung der Verarbeitung ist die Markierung gespeicherter
personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.<br/><br/>
5) Profiling<br/><br/>
Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die
darin besteht, dass diese personenbezogenen Daten verwendet werden, um
bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu
bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage,
Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder
vorherzusagen.<br/><br/>
6) Pseudonymisierung<br/>
Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise,
auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden
können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und
technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass
die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren
natürlichen Person zugewiesen werden.<br/><br/>
7) Verantwortlicher oder für die Verarbeitung Verantwortlicher<br/><br/>
Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder
gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben,
so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien
seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
vorgesehen werden.<br/><br/>
8) Auftragsverarbeiter<br/><br/>
Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung
oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
verarbeitet.<br/><br/>
9) Empfänger<br/><br/>
Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon,
ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen
eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht
der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
jedoch nicht als Empfänger.<br/><br/>
10) Dritter<br/><br/>
Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter
und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen
oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
verarbeiten.<br/><br/>
11) Einwilligung<br/><br/>
Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
informierter Weise und unmissverständlich abgegebene Willensbekundung in Form
einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der
die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
betreffenden personenbezogenen Daten einverstanden ist.<br/><br/>
2. Name und Anschrift des für die Verarbeitung Verantwortlichen<br/><br/>
Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
Bestimmungen mit datenschutzrechtlichem Charakter, ist die:<br/><br/>
Herausgeber: frank immobilien<br/>
Anschrift: Bockschützstraße 16,<br/>
83646 Bad Tölz,<br/>
Tel.: +49 (0) 8041 793-2120<br/>
E-Mail: kontakt@frankimmobilien.de<br/>
Website: http://www.frankimmobilien.de<br/><br/>
3. Cookies<br/><br/>
Die Internetseiten der frank immobilien verwenden Cookies. Cookies sind
Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt
und gespeichert werden.<br/><br/>
Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten
eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des
Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server
dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie
gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern,
den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die
andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann
über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.<br/><br/>
Durch den Einsatz von Cookies kann die frank immobilien den Nutzern dieser
Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung
nicht möglich wären.<br/><br/>
Mittels eines Cookies können die Informationen und Angebote auf unserer
Internetseite im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns,
wie bereits erwähnt, die Benutzer unserer Internetseite wiederzuerkennen. Zweck
dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu
erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss
beispielsweise nicht bei jedem Besuch der Internetseite erneut seine Zugangsdaten
eingeben, weil dies von der Internetseite und dem auf dem Computersystem des
Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie
eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein
Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.<br/><br/>
Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des
Surfverhaltens der Nutzer ermöglichen.<br/><br/>
Auf diese Weise können folgende Daten übermittelt werden:<br/><br/>
Auswahl der Homepage-Optionen<br/><br/>
Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit
sonstigen personenbezogenen Daten der Nutzer gespeichert.<br/><br/>
Beim Aufruf unserer Website wird der Nutzer über die Verwendung von Cookies zu
Analysezwecken informiert und seine Einwilligung zur Verarbeitung der in diesem
Zusammenhang verwendeten personenbezogenen Daten eingeholt. In diesem
Zusammenhang erfolgt auch ein Hinweis auf diese Datenschutzerklärung.
Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
Verwendung von Cookies zu Analysezwecken ist bei Vorliegen einer diesbezüglichen
Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.<br/><br/>
Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite
jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner
können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern
möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem
genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer
Internetseite vollumfänglich nutzbar.<br/><br/>
4. Erfassung von allgemeinen Daten und Informationen<br/><br/>
Die Internetseite der frank immobilien erfasst mit jedem Aufruf der Internetseite durch
eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen
Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den
Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten
Browsertypen und Versionen, (2) das vom zugreifenden System verwendete
Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf
unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche
über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das
Datum und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine Internet-ProtokollAdresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems
und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
von Angriffen auf unsere informationstechnologischen Systeme dienen.
Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die frank
immobilien keine Rückschlüsse auf die betroffene Person. Diese Informationen
werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite korrekt
auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu
optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
informationstechnologischen Systeme und der Technik unserer Internetseite zu
gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes
die zur Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym
erhobenen Daten und Informationen werden durch die frank immobilien daher
einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die
Datensicherheit in unserem Unternehmen zu erhöhen, um auch ein optimales
Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen
durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
Daneben werden auch, soweit die ausdrückliche Erlaubnis der betreffenden Nutzer
und unter Einhaltung der geltenden Datenschutzbestimmungen vorliegt, folgende
persönliche Daten erfasst:<br/><br/>
Vor- und Nachnamen der Nutzer<br/>
E-Mail-Adresse der Nutzer<br/>
Angaben zum Wohnort (Postleitzahl usw.)<br/>
Die Verarbeitung der personenbezogenen Daten erfolgt aufgrund unseres
berechtigten Interesses zur Erfüllung unserer vertraglich vereinbarten Leistungen und
zur Optimierung unseres Online-Angebotes.<br/><br/>
Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne
Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten
gehören z. B. die von Ihnen angeforderte Datei oder der Name Ihres InternetProviders. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person
nicht möglich.Nicht hiervon betroffen sind die IP-Adressen des Nutzers oder andere
Daten, die die Zuordnung der Daten zu einem Nutzer ermöglichen. Eine Speicherung
dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet
nicht statt.<br/><br/>
5. SSL-Verschlüsselung<br/><br/>
Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem
aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL)
über HTTPS.<br/><br/>
6. Abonnement unseres Newsletters<br/><br/>
Auf der Internetseite der frank immobilien wird den Benutzern die Möglichkeit
eingeräumt, den Newsletter unseres Unternehmens zu abonnieren. Welche
personenbezogenen Daten bei der Bestellung des Newsletters an den für die
Verarbeitung Verantwortlichen übermittelt werden, ergibt sich aus der hierzu
verwendeten Eingabemaske.<br/><br/>
Die frank immobilien informiert ihre Kunden und Geschäftspartner in regelmäßigen
Abständen im Wege eines Newsletters über Angebote des Unternehmens. Der
Newsletter unseres Unternehmens kann von der betroffenen Person grundsätzlich
nur dann empfangen werden, wenn (1) die betroffene Person über eine gültige EMail-Adresse verfügt und (2) die betroffene Person sich für den Newsletterversand
registriert. An die von einer betroffenen Person erstmalig für den Newsletterversand
eingetragene E-Mail-Adresse wird aus rechtlichen Gründen eine Bestätigungsmail im
Double-Opt-In-Verfahren versendet. Diese Bestätigungsmail dient der Überprüfung,
ob der Inhaber der E-Mail-Adresse als betroffene Person den Empfang des
Newsletters autorisiert hat.<br/><br/>
Bei der Anmeldung zum Newsletter speichern wir ferner die vom Internet-ServiceProvider (ISP) vergebene IP-Adresse des von der betroffenen Person zum Zeitpunkt
der Anmeldung verwendeten Computersystems sowie das Datum und die Uhrzeit der
Anmeldung. Die Erhebung dieser Daten ist erforderlich, um den(möglichen)
Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem späteren
Zeitpunkt nachvollziehen zu können und dient deshalb der rechtlichen Absicherung
des für die Verarbeitung Verantwortlichen.<br/><br/>
Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen
Daten werden ausschließlich zum Versand unseres Newsletters verwendet. Ferner
könnten Abonnenten des Newsletters per E-Mail informiert werden, sofern dies für
den Betrieb des Newsletter-Dienstes oder eine diesbezügliche Registrierung
erforderlich ist, wie dies im Falle von Änderungen am Newsletterangebot oder bei der
Veränderung der technischen Gegebenheiten der Fall sein könnte. Es erfolgt keine
Weitergabe der im Rahmen des Newsletter-Dienstes erhobenen personenbezogenen
Daten an Dritte. Das Abonnement unseres Newsletters kann durch die betroffene
Person jederzeit gekündigt werden. Die Einwilligung in die Speicherung
personenbezogener Daten, die die betroffene Person uns für den Newsletterversand
erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs der
Einwilligung findet sich in jedem Newsletter ein entsprechender Link. Ferner besteht
die Möglichkeit, sich jederzeit auch direkt auf der Internetseite des für die
Verarbeitung Verantwortlichen vom Newsletterversand abzumelden oder dies dem
für die Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
Teile unseres Newsletters können Werbematerial enthalten.<br/><br/>
7. Newsletter-Tracking<br/><br/>
Die Newsletter der frank immobilien enthalten sogenannte Zählpixel. Ein Zählpixel ist
eine Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im HTML-Format
versendet werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu
ermöglichen. Dadurch kann eine statistische Auswertung des Erfolges oder
Misserfolges von Online-Marketing-Kampagnen durchgeführt werden. Anhand des
eingebetteten Zählpixels kann die frank immobilien erkennen, ob und wann eine EMail von einer betroffenen Person geöffnet wurde und welche in der E-Mail
befindlichen Links von der betroffenen Person aufgerufen wurden.
Solche, über die in den Newslettern enthaltenen Zählpixel erhobenen
personenbezogenen Daten, werden, von dem für die Verarbeitung Verantwortlichen,
gespeichert und ausgewertet, um den Newsletterversand zu optimieren und den
Inhalt zukünftiger Newsletter noch besser den Interessen der betroffenen Person
anzupassen. Diese personenbezogenen Daten werden nicht an Dritte
weitergegeben. Betroffene Personen sind jederzeit berechtigt, die diesbezügliche
gesonderte, über das Double-Opt-In-Verfahren abgegebene Einwilligungserklärung
zu widerrufen. Nach einem Widerruf werden diese personenbezogenen Daten von
dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom Erhalt des
Newsletters deutet die frank immobilien automatisch als Widerruf.<br/><br/>
8. Registrierung auf unserer Internetseite<br/><br/>
Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die
Verarbeitung Verantwortlichen unter Angabe von personenbezogenen Daten zu
registrieren. Welche personenbezogenen Daten dabei an den für die Verarbeitung
Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske,
die für die Registrierung verwendet wird. Die von der betroffenen Person
eingegebenen personenbezogenen Daten werden ausschließlich für die interne
Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke
erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die
Weitergabe an einen oder mehrere Auftragsverarbeiter, beispielsweise einen
Paketdienstleister, veranlassen, der die personenbezogenen Daten ebenfalls
ausschließlich für eine interne Verwendung, die dem für die Verarbeitung
Verantwortlichen zuzurechnen ist, nutzt.<br/><br/>
Durch eine Registrierung auf der Internetseite des für die Verarbeitung
Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP) der betroffenen
Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung
gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so
der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im
Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die
Speicherung dieser Daten zur Absicherung des für die Verarbeitung Verantwortlichen
erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der
Strafverfolgung dient.<br/><br/>
Die Registrierung der betroffenen Person unter freiwilliger Angabe
personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen dazu, der
betroffenen Person Inhalte oder Leistungen anzubieten, die aufgrund der Natur der
Sache nur registrierten Benutzern angeboten werden können. Registrierten
Personen steht die Möglichkeit frei, die bei der Registrierung angegebenen
personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem
Datenbestand des für die Verarbeitung Verantwortlichen löschen zu lassen.
Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf
Anfrage Auskunft darüber, welche personenbezogenen Daten über die betroffene
Person gespeichert sind. Ferner berichtigt oder löscht der für die Verarbeitung
Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen
Person, soweit dem keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Die
Gesamtheit der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.<br/><br/>
9. Kontaktmöglichkeit über die Internetseite<br/><br/>
Die Internetseite der frank immobilien enthält aufgrund von gesetzlichen Vorschriften
Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem
Unternehmen sowie eine unmittelbare Kommunikation mit uns ermöglichen, was
ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-MailAdresse) umfasst. Sofern eine betroffene Person per E-Mail oder über ein
Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen
aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen
Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen
Person an den für die Verarbeitung Verantwortlichen übermittelten
personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe
dieser personenbezogenen Daten an Dritte.<br/><br/>
10. Routinemäßige Löschung und Sperrung von personenbezogenen Daten<br/><br/>
Der für die Verarbeitung Verantwortliche verarbeitet und speichert
personenbezogene Daten der betroffenen Person nur für den Zeitraum, der zur
Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den
europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in
Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche
unterliegt, vorgesehen wurde.<br/><br/>
Entfällt der Speicherungszweck oder läuft eine vom europäischen Richtlinien- und
Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene
Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und
entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht. In jeden Fall,
werden personenbezogene Daten mit einer maximalen Frist gelöscht: 24 Monate.<br/><br/>
11. Rechte der betroffenen Person<br/><br/>
1) Recht auf Bestätigung<br/><br/>
Jede betroffene Person hat das vom europäischen Richtlinien- und
Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende
personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses
Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.<br/><br/>
2) Recht auf Auskunft<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von
dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner
Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu
erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
betroffenen Person Auskunft über folgende Informationen zugestanden:
die Verarbeitungszwecke<br/>
die Kategorien personenbezogener Daten, die verarbeitet werden<br/>
die Empfänger oder Kategorien von Empfängern, gegenüber denen die
personenbezogenen Daten offengelegt worden sind oder noch offengelegt
werden, insbesondere bei Empfängern in Drittländern oder bei internationalen
Organisationen<br/>
Falls möglich die geplante Dauer, für die die personenbezogenen Daten
gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die
Festlegung dieser Dauer<br/>
das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
betreffenden personenbezogenen Daten oder auf Einschränkung der
Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen
diese Verarbeitung<br/>
das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben
werden: Alle verfügbaren Informationen über die Herkunft der Daten
das Bestehen einer automatisierten Entscheidungsfindung einschließlich
Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und — zumindest in diesen
Fällen — aussagekräftige Informationen über die involvierte Logik sowie die
Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung
für die betroffene Person<br/>
Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
personenbezogene Daten an ein Drittland oder an eine internationale Organisation
übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im
Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang
mit der Übermittlung zu erhalten. Möchte eine betroffene Person dieses
Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.<br/><br/>
3) Recht auf Berichtigung<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die
unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu
verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung
der Zwecke der Verarbeitung, die Vervollständigung unvollständiger
personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu
verlangen. Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch
nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
Verantwortlichen wenden.<br/><br/>
4) Recht auf Löschung (Recht auf Vergessen werden)<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgebejkilr gewährte Recht, von dem
Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten
unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit
die Verarbeitung nicht erforderlich ist:
Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf
sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.
Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a
DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
Verarbeitung.<br/>
Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen
die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für
die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2
DSGVO Widerspruch gegen die Verarbeitung ein.<br/>
Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br/>
Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
erforderlich, dem der Verantwortliche unterliegt.<br/>
Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.<br/><br/>
Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die
Löschung von personenbezogenen Daten, die bei der frank immobilien gespeichert
sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der frank immobilien wird
veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.Wurden
die personenbezogenen Daten von der frank immobilien öffentlich gemacht und ist
unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung
der personenbezogenen Daten verpflichtet, so trifft die frank immobilien unter
Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
angemessene Maßnahmen, auch technischer Art, um andere für die
Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen
Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von
diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher
Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht
erforderlich ist. Der Mitarbeiter der frank immobilien wird im Einzelfall das
Notwendige veranlassen.<br/><br/>
5) Recht auf Einschränkung der Verarbeitung<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der
folgenden Voraussetzungen gegeben ist:<br/><br/>
Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person
bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
Richtigkeit der personenbezogenen Daten zu überprüfen.<br/><br/>
Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der
Nutzung der personenbezogenen Daten.<br/><br/>
Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der
Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur
Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1
DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des
Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene
Person die Einschränkung von personenbezogenen Daten, die bei der frank
immobilien gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an
einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter
der frank immobilien wird die Einschränkung der Verarbeitung veranlassen.<br/><br/>
6) Recht auf Datenübertragbarkeit<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie
betreffenden personenbezogenen Daten, welche durch die betroffene Person einem
Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und
maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten
einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die
Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art.
9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1
Buchstabe b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich
ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
welche dem Verantwortlichen übertragen wurde. Ferner hat die betroffene Person bei
der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO
das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies
technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer
Personen beeinträchtigt werden. Zur Geltendmachung des Rechts auf
Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen Mitarbeiter
der frank immobilien wenden.<br/><br/>
7) Recht auf Widerspruch<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen,
die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1
Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein
auf diese Bestimmungen gestütztes Profiling. Die frank immobilien verarbeitet die
personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir
können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den
Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die
Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
Rechtsansprüchen. Verarbeitet die frank immobilien personenbezogene Daten, um
Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit
Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke
derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
Direktwerbung in Verbindung steht. Widerspricht die betroffene Person gegenüber
der frank immobilien der Verarbeitung für Zwecke der Direktwerbung, so wird die
frank immobilien die personenbezogenen Daten nicht mehr für diese Zwecke
verarbeiten. Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus
ihrer besonderen Situation ergeben, gegen die sie betreffende Verarbeitung
personenbezogener Daten, die bei der frank immobilien zu wissenschaftlichen oder
historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs.
1 DSGVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung
ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich. Zur
Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden
Mitarbeiter der frank immobilien oder einen anderen Mitarbeiter wenden. Der
betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von
Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
technische Spezifikationen verwendet werden.<br/><br/>
8) Automatisierte Entscheidungen im Einzelfall, einschließlich Profiling<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche
Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen
der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2) aufgrund
von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten
Interessen der betroffenen Person enthalten oder (3) mit ausdrücklicher Einwilligung
der betroffenen Person erfolgt. Ist die Entscheidung (1) für den Abschluss oder die
Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen
erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen
Person, trifft die frank immobilien angemessene Maßnahmen, um die Rechte und
Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren,
wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der
Entscheidung gehört. Möchte die betroffene Person Rechte mit Bezug auf
automatisierte Entscheidungen geltend machen, kann sie sich hierzu jederzeit an
einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.<br/><br/>
9) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung<br/><br/>
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine
Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend
machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
Verantwortlichen wenden.<br/><br/>
12. Verwendung von Google Analytics<br/><br/>
Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
(folgend: Google). Google Analytics verwendet sog. „Cookies", also Textdateien, die
auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über
Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in
den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IPAnonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch
innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor
gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um
weitere mit der Websitenutzung und der Internetnutzung verbundene
Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen
von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
anderen Daten von Google zusammengeführt.<br/><br/>
Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der
Website und in der Zusammenstellung von Reports über Aktivitäten auf der Website.
Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere
verbundene Dienstleistungen erbracht werden. Die Verarbeitung beruht auf dem
berechtigten Interesse des Webseitenbetreibers.<br/><br/>
Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
werden nutzen können. Sie können darüber hinaus die Erfassung, der durch das
Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IPAdresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
und installieren: Browser Add On zur Deaktivierung von Google Analytics.<br/><br/>
Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking durch
Google Analytics auf unseren Seiten unterbinden, indem Sie diesen Link anklicken.
Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung
durch Google Analytics für diese Website und für diesen Browser zukünftig
verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.<br/><br/>
13. Social Media Plug-ins<br/><br/>
Auf unseren Webseiten werden Social Plugins der unten aufgeführten Anbieter
eingesetzt. Die Plugins können Sie daran erkennen, dass sie mit dem
entsprechenden Logo gekennzeichnet sind.<br/><br/>
Über diese Plugins werden unter Umständen Informationen, zu denen auch
personenbezogene Daten gehören können, an den Dienstebetreiber gesendet und
ggf. von diesem genutzt. Wir verhindern die unbewusste und ungewollte Erfassung
und Übertragung von Daten an den Diensteanbieter durch eine 2-Klick-Lösung. Um
ein gewünschtes Social Plugin zu aktivieren, muss dieses erst durch Klick auf den
entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung des Plugins
wird auch die Erfassung von Informationen und deren Übertragung an den
Diensteanbieter ausgelöst. Wir erfassen selbst keine personenbezogenen Daten
mittels der Social Plugins oder über deren Nutzung.<br/><br/>
Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin erfasst und wie
diese durch den Anbieter verwendet werden. Derzeit muss davon ausgegangen
werden, dass eine direkte Verbindung zu den Diensten des Anbieters ausgebaut wird
sowie mindestens die IP-Adresse und gerätebezogene Informationen erfasst und
genutzt werden. Ebenfalls besteht die Möglichkeit, dass die Diensteanbieter
versuchen, Cookies auf dem verwendeten Rechner zu speichern. Welche konkreten
Daten hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den
Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls Sie zeitgleich
bei Facebook angemeldet sind, kann Facebook Sie als Besucher einer bestimmten
Seite identifizieren.<br/><br/>
Wir haben auf unserer Website die Social-Media-Buttons folgender Unternehmen
eingebunden:<br/><br/>
Facebook, https://facebook.com<br/><br/>
14. Zahlungsart<br/><br/>
1) Paypal: Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
Komponenten von PayPal integriert. PayPal ist ein Online-Zahlungsdienstleister.
Zahlungen werden über sogenannte PayPal-Konten abgewickelt, die virtuelle Privatoder Geschäftskonten darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle
Zahlungen über Kreditkarten abzuwickeln, wenn ein Nutzer kein PayPal-Konto
unterhält. Ein PayPal-Konto wird über eine E-Mail-Adresse geführt, weshalb es keine
klassische Kontonummer gibt. PayPal ermöglicht es, Online-Zahlungen an Dritte
auszulösen oder auch Zahlungen zu empfangen. PayPal übernimmt ferner
Treuhänderfunktionen und bietet Käuferschutzdienste an.<br/><br/>
Die Europäische Betreibergesellschaft von PayPal ist die PayPal (Europe) S.à.r.l. &
Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg, Luxemburg.<br/><br/>
Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop
als Zahlungsmöglichkeit „PayPal" aus, werden automatisiert Daten der betroffenen
Person an PayPal übermittelt. Mit der Auswahl dieser Zahlungsoption willigt die
betroffene Person in die zur Zahlungsabwicklung erforderliche Übermittlung
personenbezogener Daten ein.<br/><br/>
Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich in der
Regel um Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse,
Telefonnummer, Mobiltelefonnummer oder andere Daten, die zur
Zahlungsabwicklung notwendig sind. Zur Abwicklung des Kaufvertrages notwendig
sind auch solche personenbezogenen Daten, die im Zusammenhang mit der
jeweiligen Bestellung stehen.<br/><br/>
Die Übermittlung der Daten bezweckt die Zahlungsabwicklung und die
Betrugsprävention. Der für die Verarbeitung Verantwortliche wird PayPal
personenbezogene Daten insbesondere dann übermitteln, wenn ein berechtigtes
Interesse für die Übermittlung gegeben ist. Die zwischen PayPal und dem für die
Verarbeitung Verantwortlichen ausgetauschten personenbezogenen Daten werden
von PayPal unter Umständen an Wirtschaftsauskunfteien übermittelt. Diese
Übermittlung bezweckt die Identitäts- und Bonitätsprüfung.<br/><br/>
PayPal gibt die personenbezogenen Daten gegebenenfalls an verbundene
Unternehmen und Leistungserbringer oder Subunternehmer weiter, soweit dies zur
Erfüllung der vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag
verarbeitet werden sollen.<br/><br/>
Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
personenbezogenen Daten jederzeit gegenüber PayPal zu widerrufen. Ein Widerruf
wirkt sich nicht auf personenbezogene Daten aus, die zwingend zur
(vertragsgemäßen) Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden
müssen.<br/><br/>
Die geltenden Datenschutzbestimmungen von PayPal können unter
https://www.paypal.com/de/webapps/mpp/ua/privacy-full abgerufen werden.<br/><br/>
15. Google Ads<br/><br/>
Diese Website verwendet Google Ads. Google Ads ist ein Online-Werbeprogramm
der Google Ireland Limited („Google"), Gordon House, Barrow Street, Dublin 4,
Irland.<br/><br/>
Im Rahmen von Google Ads nutzen wir das so genannte Conversion-Tracking. Wenn
Sie auf eine von Google geschaltete Anzeige klicken wird ein Cookie für das
Conversion-Tracking gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die
der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren
nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der
Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und das Cookie ist
noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die
Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.<br/><br/>
Jeder Google Ads-Kunde erhält ein anderes Cookie. Die Cookies können nicht über
die Websites von Google Ads-Kunden nachverfolgt werden. Die mithilfe des
Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken
für Google Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden
haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige
geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich
Nutzer persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen
möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie des
Google Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen
leicht deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken
aufgenommen.<br/><br/>
Die Speicherung von „Conversion-Cookies" und die Nutzung dieses Tracking-Tools
erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende
Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO;
die Einwilligung ist jederzeit widerrufbar.<br/><br/>
Mehr Informationen zu Google Ads und Google Conversion-Tracking finden Sie in
den Datenschutzbestimmungen von Google:<br/>
https://policies.google.com/privacy?hl=de.<br/><br/>
Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der
Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
kann die Funktionalität dieser Website eingeschränkt sein.<br/><br/>
16. Google Remarketing<br/><br/>
Diese Website verwendet die Remarketing-Funktion der Google Inc. Die Funktion
dient dazu, Webseitenbesuchern innerhalb des Google-Werbenetzwerks
interessenbezogene Werbeanzeigen zu präsentieren. Im Browser des
Webseitenbesuchers wird ein sog. „Cookie" gespeichert, der es ermöglicht, den
Besucher wiederzuerkennen, wenn dieser Webseiten aufruft, die dem
Werbenetzwerk von Google angehören. Auf diesen Seiten können dem Besucher
Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen, die der Besucher
zuvor auf Webseiten aufgerufen hat, die die Remarketing Funktion von Google
verwenden.<br/><br/>
Nach eigenen Angaben erhebt Google bei diesem Vorgang keine
personenbezogenen Daten. Sollten Sie die Funktion Remarketing von Google
dennoch nicht wünschen, können Sie diese grundsätzlich deaktivieren, indem Sie die
entsprechenden Einstellungen unter http://www.google.com/settings/ads vornehmen.
Alternativ können Sie den Einsatz von Cookies für interessenbezogene Werbung
über die Werbenetzwerkinitiative deaktivieren, indem Sie den Anweisungen unter
http://www.networkadvertising.org/managing/opt_out.asp folgen.<br/><br/>
17. Rechtsgrundlage der Verarbeitung<br/><br/>
Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für
Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für
eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DSGVO.
Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten
oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch
welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie
beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf
Art. 6 I lit. c DSGVO. In seltenen Fällen könnte die Verarbeitung von
personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der
betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre
beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden
würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder
sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige
Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
DSGVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DSGVO
beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von
keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung
zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des
Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
deshalb gestattet, weil sie durch den europäischen Gesetzgeber besonders erwähnt
wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).<br/><br/>
18. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen
oder einem Dritten verfolgt werden<br/><br/>
Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DSGVO ist
unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten
des Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.<br/><br/>
19. Dauer, für die die personenbezogenen Daten gespeichert werden<br/><br/>
Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die
jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die
entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur
Vertragserfüllung oder Vertragsanbahnung erforderlich sind.<br/><br/>
20. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
Verpflichtung der betroffenen Person, die personenbezogenen Daten
bereitzustellen; mögliche Folgen der Nichtbereitstellung<br/><br/>
Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum
Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus
vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene
Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch
uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet
uns personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr
einen Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten
hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen werden
könnte. Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen
muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter
klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den
Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
personenbezogenen Daten bereitzustellen, und welche Folgen die
Nichtbereitstellung der personenbezogenen Daten hätte.<br/><br/>
21. Änderung der Datenschutzbestimmung<br/><br/>
Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den
aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer
Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.<br/><br/>
22. Bestehen einer automatisierten Entscheidungsfindung<br/><br/>
Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
Entscheidungsfindung oder ein Profiling.
            </Content>
        </Div>
    )
}

export default Datenschutz;