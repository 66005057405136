import styled from "styled-components";

const Div = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Content = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const Header = styled.h1`
    margin: 30px 0;
`;

function Impressum() {
    return (
        <Div>
            <Content>
            <Header>I M P R E S S U N G</Header>
            <b>Angaben gemäß § 5 TMG</b><br/><br/>
frank IMMOBILIEN<br/>
Bockschützstraße 16<br/>
83646 Bad Tölz<br/>
Fon +49 (0) 8041 793 2120<br/>
Mobil +49 (0) 176 55851133<br/>
Mail: kontakt@frankimmobilien.de<br/>
Web: www.frankimmo.de<br/><br/>

<b>Vertretungsberechtigter:</b> <br/>
Jürgen Frank<br/><br/>

<b>Berufskammer:</b><br/>
IHK für München und Oberbayern Max-Joseph-Str. 2, 80333 München<br/><br/>

<b>Aufsichtsbehörde:</b><br/>
IHK für München und Oberbayern Max-Joseph-Str. 2, 80333 München<br/>
Immobilienmakler gem. § 34c GewO<br/><br/>

<b>Redaktionell Verantwortlich:</b><br/>
Jürgen Frank<br/><br/>



<b>Verbraucherinformationen zur alternativen Streitschlichtung:</b><br/>
(Art 14 (1) ODR-VO und $ 36 VSBG)<br/>
Die Internetplattformen der Europäischen Union zur Online-Streitbeilegung (sog. „OS-Plattform“) für Verbraucher ist unter dem folgendem Link erreichbar: http://ec.europa.eu/consumers/odr<br/>
Wir nehmen nicht an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.<br/><br/>



            </Content>
        </Div>
    )
}

export default Impressum;