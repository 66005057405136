import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import sacherl from "../../../../images/immobilien/sacherl.webp";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageEins() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={sacherl} />
                <DataBox>
                    <BlockBox>
                        <H3s>1.500.000 €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    <BlockBox>
                        <H3>83671 Benediktbeuern</H3>
                        <p>Adresse</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>4</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        {/* <H3>216 m²</H3>
                        <p>Wohnfläche</p> */}
                    </BlockBox>
                    <BlockBox>
                        <H3>16.250 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox>
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Kleines Sacherl mit viel Potential</Header1>
                <HR />
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        {/* <p>Etagenanzahl:</p> */}
                        {/* <p>Wohnfläche ca:</p> */}
                        {/* <p>Nutzfläche ca.:</p> */}
                        <p>Grundstück ca.:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Bauernhaus</p>
                        {/* <p>4</p> */}
                        <p>16.250 m²</p>
                        {/* <p>168 m²</p> */}
                        {/* <p>1.366 m²</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>Grundstück ca.:</p> */}
                        <p>Zimmer:</p>
                        <p>Schlafzimmer :</p>
                        {/* <p>Badezimmer :</p>
                        <p>Garage/ Stellplatz :</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>4</p>
                        <p>2</p>
                        {/* <p>4</p>
                        <p>2</p> */}
                        {/* <p>2 Garagen</p> */}
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Baujahr:</p>
                        {/* <p>Bauphase:</p>
                        {/* <p>Objektzustand:</p> */}
                        <p>Heizungsart:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>unbekannt</p>
                        <p>Ofenheizung</p>
                        {/* <p>Renovierungsbedürftig</p>
                        <p>Öl-Heizung</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Wesentliche Energieträger:</p>
                        <p>Energieausweis:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Holz</p>
                        <p>laut Gesetz nicht erforderlich</p>
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>EINE EINMALIGE GELEGENHEIT  im Landkreis Bad Tölz - Wolfratshausen<br/><br/>
 
 Kleiner Bauernhof mit 9 Grundstücken in herrlicher Lage.<br/><br/>
 
 Vielfältige Nutzungsmöglichkeiten durch das Gesamtpaket mit Siedlungsfläche, landwirtschaftlicher Fläche (Grünland, Wiesen), Wald und Moor  - eine interessante Investition in die Zukunft!<br/><br/>
  
 - unweit vom Ortskern, Kloster und Siedlungsgebiet<br/>
   
 - traumhaft sonnige Südwest-Ausrichtung<br/>
 
 - Zwei Grundstücke Bauerwartungsland, kein Bauland !</p>

            <HR/>
            <h2>Ausstattung</h2>
            <p>Stark sanierungsbedürftig. Die Nutzung kann nur im Einvernehmen mit der Gemeinde erfolgen
            </p>

            <HR/>
            <h2>Lage</h2>
            <p>Ruhige Alleinlage im Aussenbereich. <br/>
Ein Rastplatz für die Seele<br/><br/>
 
Ein Paradies für Naturliebhaber und sportlich Aktive<br/><br/>
 
Zum Kochelsee sind es ca. 8 Autominuten<br/><br/>
 
herrliche idyllische Natur-Lage nahe den Bergen des bayerischen Oberlandes<br/><br/>
  
guter Anschluss an die charmante und pulsierende Metropole München</p>
            </InhaltBox>
        </Content>
        </>
    )
}

export default ImmoPageEins;