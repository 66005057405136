import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import wackersberg from "../../../../images/immobilien/wackersberg.webp";
import wackersberg2 from "../../../../images/immobilien/wackersberg2.webp";


const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageEins() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={wackersberg2} />
                <DataBox>
                    <BlockBox>
                        <H3s>Auf Anfrage</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    <BlockBox>
                        <H3>83646 Wackersberg</H3>
                        <p>Adresse</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>5</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>200 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>1.366 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox>
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Einfamilienhaus in Wackersberg</Header1>
                <HR />
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        <p>Etagenanzahl:</p>
                        <p>Wohnfläche ca:</p>
                        <p>Nutzfläche ca.:</p>
                        <p>Grundstück ca.:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Einfamilienhaus (freistehend)</p>
                        <p>3</p>
                        <p>200 m²</p>
                        <p>160 m²</p>
                        <p>1.366 m²</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Bezugsfrei ab :</p>
                        <p>Zimmer:</p>
                        <p>Schlafzimmer :</p>
                        <p>Badezimmer :</p>
                        <p>Garage/ Stellplatz :</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>sofort</p>
                        <p>5</p>
                        <p>4</p>
                        <p>2</p>
                        <p>2 Garagen</p>
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Baujahr:</p>
                        <p>Sanierung:</p>
                        <p>Objektzustand:</p>
                        <p>Heizungsart:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>1954</p>
                        <p>zuletzt 1974</p>
                        <p>Renovierungsbedürftig</p>
                        <p>Öl-Heizung</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Wesentliche Energieträger:</p>
                        <p>Energieausweis:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Öl</p>
                        <p>liegt zur Besichtigung vor</p>
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Seltene Gelegenheit! Charmantes Einfamilienhaus mit Esprit!<br/><br/>

Das  ursprüngliche Haus wurde vermutlich in den 1930er Jahren erbaut. 1954 wurde es weiter neu ausgebaut und aufgestockt. Es besticht vor allem durch seine gemütliche Atmosphäre und seine freundlichen, hellen Räume in herrlicher Aussichtslage. Highlight ist das großzügige Wohnzimmer mit ca. 55 qm Wohnfläche mit urigem Kachelofen und phänomenalem Ausblick in die Berge. Das Dachgeschoß bietet zusätzlich zu den 180 qm Wohnfläche weiteres  beträchtliches Potential - je nach Platzbedarf!<br/><br/>

Das gesamte Anwesen befindet sich auf einem 1201 qm großen Grundstück in idyllischer, erholsamer und naturnaher Alleinlage. Es liegt zwischen Bad Tölz und Lenggries, nahe der Isar - im schönen Isartal, abseits von städtischem Trubel. Das Grundstück ist von der Straße nicht einsehbar und man hat dadurch eine geschützte und entspannte Privatsphäre im Garten und auf der sonnenverwöhnten Terrasse nach Süden, Westen und Osten. Durch die Lage im Aussenbereich bleibt diese Abgeschiedenheit und der grandiose Ausblick gewahrt. Ein idealer Zufluchtsort für Gestresste, Naturliebhaber und Individualisten mit bester Anbindung. <br/><br/>

Die beiden Garagen sind aufgeteilt in eine Einzelgarage oben am Haus und eine zweite Einzelgarage unterhalb auf einem weiteren kleinen Grundstück. Ein zusätzliches Holzhäuschen  erweitert die Nutzfläche ideal.<br/><br/>

Die Immobilie - und ihre Details: <br/><br/>

- Wohnfläche ca. 200 qm plus Dachgeschoss<br/>
- Nutzfläche ca. 160 qm<br/>
- 2 Balkone und eine sehr große Terrasse, da hier ursprünglich ein Café geplant war<br/>
- große Grundstücksfläche mit ca. 1201 qm plus extra 165 qm <br/>
- 5 Räume<br/>
- 2 Bäder, ins im EG, und eins im OG<br/>
- Das Haus ist teilunterkellert<br/>
- 2 Garagen <br/>
- SAT Empfang<br/>
- weitläufiger, alt eingewachsener Garten<br/>
- Kachelofen für wohlige Wärme, heutzutage unerlässlich<br/>
- Wohnküche mit kultigem Wamsler Holzbackofen und Speisekammer<br/>
- Echte Holzbalkendecke</p>
            
            <HR/>
            <h2>Lage</h2>
            <p>Phänomenale Lage zwischen Bad Tölz und Lenggries!<br/><br/>

In unmittelbarer Nähe erreichen Sie per pedes oder mit dem Fahrrad das herrliche Isar-Ufer.<br/><br/>

Wohnen Sie an einem der begehrtesten Plätze Deutschlands umgeben von wunderbaren bayrischen Badeseen.<br/><br/>

Bad Tölz, die  WellVital-Stadt ist heilklimatische Kurstadt und soll bereits zu keltischer Zeit als Jodheilquelle genutzt worden sein. <br/><br/>
Die Marktstraße von Bad Tölz gehört zu den schönsten Straßenzügen Bayerns und beginnt direkt an der Isar-Brücke. Mit ihrer Fußgängerzone mit neuer Natursteinpflasterung nach altem Vorbild verdankt sie ihre heutige Erscheinung vor allem dem Münchner Architekten und Heimatpfleger Gabriel von Seidl.<br/><br/>

Lenggries - das Berg und Wanderparadies - eignet sich hervorragend für Sportaktivitäten sowohl im Sommer als auch im Winter. Für Extremsportler bietet sich der Klettergarten am Brauneck, das Isar-Rafting oder Drachen- und Gleitschirmflüge vom Brauneck an.<br/><br/>

Das Isartal und seine Umgebung ist eines der schönsten Urlaubsgebiete Deutschlands mit weltbekannten Sehenswürdigkeiten. Gut markierte Wander- und Radwanderwege gibt es in Hülle und Fülle und an den typisch oberbayerischen Gaststätten und Biergärten mit ihrer bekannten Schmankerlküche kommt man nur schwerlich vorbei.<br/><br/>

Selbstverständlich ist das Angebot für Familien bezüglich Schulbildung perfekt. Von der Grundschule über Mittelschule, Realschule, Fachoberschule bis hin zum Gymnasium sind alle Schulrichtungen vorhanden.<br/><br/>

BAB-Anbindung:<br/><br/>
A95 Garmisch-München (Südwest).<br/>
A8 Salzburg-München (Südost).<br/>
Zum Flughafen München im Norden sind es ca. 1,1 Std.<br/>
B472 Ostwest-Verbindung<br/>
Eine Bushaltestelle ist unweit schnell zu erreichen.<br/>
Wunderschöner Isarradweg von Bad Tölz über Lenggries bis zum Sylvensteinspeichersee<br/><br/>

Guter Anschluss an die charmante und pulsierende Metropole München.</p>
            </InhaltBox>


            {/* <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   

Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   

Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer</Text> */}
        </Content>
        </>
    )
}

export default ImmoPageEins;