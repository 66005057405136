import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import AboutPage from './components/pages/AboutPage';
import AlleImmosPage from './components/pages/AlleImmosPage';
import StartPage from "./components/pages/StartPage";
import Header from './components/molecules/header';
import styled from 'styled-components';
import "./App.css"
import Footer from './components/molecules/footer';
import ImmoPageEins from "./components/pages/Immobilien/ImmoPageEins";
import ImmoPageZwei from "./components/pages/Immobilien/ImmoPageZwei";
import ImmoPageDrei from "./components/pages/Immobilien/ImmoPageDrei";
import ImmoPageVier from "./components/pages/Immobilien/ImmoPageVier";
import ImmoPageFunf from "./components/pages/Immobilien/ImmoPageFunf";
import ImmoPageSechs from "./components/pages/Immobilien/ImmoPageSechs";
import ImmoPageSieben from "./components/pages/Immobilien/ImmoPageSieben";
import Datenschutz from "./components/pages/Datenschutz";
import Impressum from "./components/pages/Impressum";
import Widerruf from "./components/pages/Widerruf";

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
`;

function App() {
  return (
    
    <Router >
      <Header />
        <Body>
      <Routes>
          <Route path="/" element={<StartPage />}/>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/immobilien" element={<AlleImmosPage />} /> 
          <Route path="/immobilie1" element={<ImmoPageEins />} /> 
          <Route path="/immobilie2" element={<ImmoPageZwei />} /> 
          <Route path="/immobilie3" element={<ImmoPageDrei />} /> 
          <Route path="/immobilie4" element={<ImmoPageVier />} /> 
          {/* <Route path="/immobilie5" element={<ImmoPageFunf />} />  */}
          <Route path="/immobilie6" element={<ImmoPageSechs />} /> 
          {/* <Route path="/immobilie7" element={<ImmoPageSieben />} />  */}
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/widerruf" element={<Widerruf />} />
      </Routes>
        </Body>
      <Footer />
    </Router>
    
  );
}

export default App;
