import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import sudfrankreich from "../../../../images/immobilien/sudfrankreich.webp";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageEins() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={sudfrankreich} />
                <DataBox>
                    <BlockBox>
                        <H3s>985.000 €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    {/* <BlockBox>
                        <H3>740 m²</H3>
                        <p>Fläche</p>
                    </BlockBox> */}
                    <BlockBox>
                        <H3>15</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>504 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>38.793 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox>
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Schloss in Süd-Frankreich in ruhiger Lage mit Blick auf die Pyrenäen</Header1>

                
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        <p>Etagenanzahl:</p>
                        <p>Wohnfläche ca:</p>
                        <p>Nutzfläche ca.:</p>
                        <p>Grundstück ca.:</p>
                        <p>Bezugsfrei ab:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Burg/Schloss</p>
                        <p>4</p>
                        <p>504 m²</p>
                        <p>669 m²</p>
                        <p>38.793 m²</p>
                        <p>sofort</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Zimmer:</p>
                        <p>Schlafzimmer:</p>
                        <p>Badezimmer:</p>
                        <p>Garage/ Stellplatz:</p>
                        {/* <p>Internet:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>15</p>
                        <p>6</p>
                        <p>3</p>
                        <p>2 Stellplätze</p>
                        {/* <p>2 Garagen</p> */}
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Modernisierung/ Sanierung:</p>
                        {/* <p>Sanierung:</p> */}
                        <p>Objektzustand:</p>
                        {/* <p>Heizungsart:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>1954</p> */}
                        {/* <p>zuletzt 1974</p> */}
                        <p>zuletzt 2022</p>
                        <p>Gepflegt</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Denkmalschutzobjekt:</p>
                        {/* <p>Ausstattung:</p> */}
                        <p>Heizungsart:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Ja</p>
                        {/* <p>Gehobene Qualität</p> */}
                        <p>Zentralheizung</p>
                    </AlignBoxItem>
                </AlignBox>



                
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Das Schloss stammt aus dem 18.Jahrhundert. Es wurde auf den Überresten einer alten Burg gebaut, die von der mittelalterlichen Vergangenheit der Stadt zeugt und steht auf einem Hügel.<br/><br/>

Das Chateau besteht aus zwei Vollgeschossen, ausgebautem Dachboden und ist komplett unterkellert. <br/><br/>

Aufteilung:<br/>
Erdgeschoss: Rezeption, riesiges Wohnzimmer, Arbeitszimmer, Salon, Speisezimmer, Küche (erweiterbar mit einem Wintergarten) und Gäste-WC.<br/>
Obergeschoss: Master-Schlafzimmer mit Bad und Ankleidezimmer, ein weiteres Master Schlafzimmer mit Bad und Ankleideraum, Fernsehzimmer und Arbeitsraum.<br/>
Dachgeschoss: Turmzimmer mit Galerie, 4 Zimmer, großes Bad und WC.<br/><br/>

Der großzügige Keller besteht aus diversen Lagerräumen, Weinkeller, Heizungsraum und einer Garage mit einer Fläche von ca. 165 qm</p>

            <HR/>
            <h2>Ausstattung</h2>
            <p>▪ Öl-Zentralheizung<br/>
                ▪ Offene Marmor/Granit-Kamine in beinahe jedem Raum<br/>
                ▪ Separates Bibliothek-Gebäude: ca. 25m2<br/>
                ▪ Pferdestallungen für 8 Pferde<br/>
                ▪ Inkl. Sattelkammer<br/>
                ▪ Futterhaus: ca. 15m2<br/>
                ▪ Reitwiese bzw. Koppel<br/>
                ▪ Großes Entenhaus<br/>
                ▪ Bauerwartungsland: 2.940m2<br/>
                ▪ Eigene Quelle: für landw. Bewirtschaftung<br/>
            </p>

            <HR/>
            <h2>Lage</h2>
            <p>Das Chateau befindet sich in der Region Aquitaine / Pyrénnées Atlantiques. <br/>
Nächste Flughäfen befinden sich in Pau oder Tarbes: ca. 30 Autominuten<br/>
Das Pilgerzentrum Lourdes ist ca. 25 Autominuten entfernt.<br/><br/>

Die naheliegende Pyrenäen-Bergkette ist in ca. 1 Stunde erreichbar und bietet Skifahren, Schneeschuwandern, Radfahren, Wildwasser-Rafting und Paragliding.<br/><br/>

Die Silberküste und die berühmten Surfstrände von Biarritz befinden sich in  ca. 1 Std. 40 Min. Entfernung.<br/>
Das Gebiet bietet einen sanften Tourismus und ist berühmt durch das weltbekannte Juracon- Weinbaugebiet, das Pilgerzentrum Lourdes und Baskischer Schinken.<br/><br/>

Das kleine Französische Dorf  liegt in einer Gegend mit besonders hoher Lebensqualität und hohem Freizeit- und Erholungswert. Die wunderschöne Natur sowie die weite Sicht mit Bergblick laden viele Gäste zu kleinen Ausflügen in die nähere Umgebung ein. In allen Himmelsrichtungen gibt es viele grüne Wiesen, Berge, Wald und reine Natur mit himmlischen Wanderwegen...<br/>
Für Naturliebhaber, die eine traumhafte, idyllische, ruhige und entspannte Atmosphäre schätzen, ist die Gegend extrem reizvoll, aber dennoch liegt das Schloss äußerst verkehrsgünstig.</p>
            </InhaltBox>
        </Content>
        </>
    )
}

export default ImmoPageEins;