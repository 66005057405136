import styled from "styled-components";
import { Link } from 'react-router-dom';


const AlleImmos = styled(Link)`
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 40px;
    padding: 5px;

    &:hover{
        background-color: #f2f2f2
        ;
    }
`;

function StyledLink({destination, content}) {
    return(
        <AlleImmos onClick={() => {window.location.href=destination}}>{content}</AlleImmos>
    )
}

export default StyledLink;