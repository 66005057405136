import styled from "styled-components";
// import immo1 from "../../../images/immobilien/immo1.jpg";
import laax from "../../../../images/immobilien/laax.webp";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageSechs() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={laax} />
                <DataBox>
                    <BlockBox>
                        <H3s>670.000 €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    {/* <BlockBox>
                        <H3>740 m²</H3>
                        <p>Fläche</p>
                    </BlockBox> */}
                    <BlockBox>
                        <H3>1,5</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>63 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    {/* <BlockBox>
                        <H3>38.793 m²</H3>
                        <p>Grundstück</p>
                    </BlockBox> */}
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>1,5 Zimmer Ferienwohnung mit traumhaftem Berg- und Seeblick, 2 Bädern und Sauna</Header1>

                
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        <p>Etage:</p>
                        <p>Wohnfläche ca:</p>
                        <p>Bezugsfrei ab:</p>
                        <p>Zimmer:</p>
                        {/* <p>Grundstück ca.:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Erdgeschosswohnung</p>
                        <p>0 von 4</p>
                        <p>63 m²</p>
                        <p>sofort</p>
                        <p>1,5</p>
                        {/* <p>sofort</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>Zimmer:</p> */}
                        <p>Schlafzimmer:</p>
                        <p>Badezimmer:</p>
                        <p>Garage/ Stellplatz:</p>
                        {/* <p>Internet:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>1</p>
                        <p>2</p>
                        <p>2 Tiefgaragen-Stellplätze</p>
                        {/* <p>2 Stellplätze</p> */}
                        {/* <p>2 Garagen</p> */}
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Baujahr:</p>
                        <p>Objektzustand:</p>
                        {/* <p>Sanierung:</p> */}
                        {/* <p>Heizungsart:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>1954</p> */}
                        {/* <p>zuletzt 1974</p> */}
                        <p>1989</p>
                        <p>Gepflegt</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>Denkmalschutzobjekt:</p> */}
                        <p>Ausstattung:</p>
                        <p>Heizungsart:</p>
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>Ja</p> */}
                        <p>Gehobene Qualität</p>
                        <p>Zentralheizung</p>
                    </AlignBoxItem>
                </AlignBox>



                
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Pfiffige eineinhalb Zimmer Wohnung in einem kleinen Mehrfamilienhaus in der Schweiz. In direkter Nähe befindet sich ein Skilift und der Laaxersee (Lag Grand).<br/>
Sie können hier direkt von der Skipiste bis zum Haus fahren. Es ist die  perfekte FERIENWOHNUNG in jeder Jahreszeit!</p>

            <HR/>
            <h2>Ausstattung</h2>
            <p>- 2 große Terrassen nach Süden und Westen<br/>

- Wohnbereich mit offener eingebauter Küche mit Miele-Elektrogeräten<br/>

- sehr schöner Kamin im Wohnzimmer<br/>

- große Flügeltür zur traumhaften Terrasse<br/>

- integriertes Klappbett (Doppelbett) in der Schrankwand<br/>

- separate Speise und Abstellkammer<br/>

- Schreiner-Einbaumöbel im Flur und in beiden Zimmern<br/>

- 1 luxuriöses Bad bis oben gefliest,  ein weiteres Bad mit Toilette, Waschbecken und Bidet. Einbau einer Dusche möglich<br/>

- Sauna im Appartement, wie neu!<br/>

- großer Kellerraum mit eigenem Waschbereich (Anschluss mit Waschbecken, Waschmaschine und Trockner)<br/>

- ebenerdiger Zugang zum Haus oder über TG mit Aufzug<br/>

- Die beiden Tiefgaragenstellplätze a`35.000 EUR müssen zusätzlich dazu erworben werden<br/>
            </p>

            <HR/>
            <h2>Lage</h2>
            <p>Das kleine Schweizer Dorf Laax liegt in einer Gegend mit besonders hoher Lebensqualität und hohem Freizeit- und Erholungswert. Die wunderschöne Natur sowie die weite Sicht mit Berg- und Seeblick laden viele Gäste zu kleinen Ausflügen in die nähere Umgebung ein. In allen Himmelsrichtungen gibt es viele grüne Wiesen, Berge, Wald, den See und reine Natur mit himmlischen Wanderwegen..
Für Naturliebhaber, die eine traumhafte, idyllische, ruhige und entspannte Atmosphäre schätzen, ist die Gegend extrem reizvoll, aber dennoch liegt die Wohnung äußerst verkehrsgünstig.<br/><br/>

Von der Wohnung haben Sie die Möglichkeit  weiter nach St. Moritz oder nach Italien reisen.</p>
            </InhaltBox>
        </Content>
        </>
    )
}

export default ImmoPageSechs;