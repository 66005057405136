import styled from "styled-components";
import laax from "../../../../images/immobilien/innsbruck.webp";
import tirol from "../../../../images/immobilien/Tirol_aussen.jpeg";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Image = styled.img`
    width: 60%;
`;

const HeadBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 80%;
`;

const DataBox = styled.div`
    margin-left: 80px;
    // width: 100%;
    justify-content: space-between;
    
`;

const BlockBox = styled.div`
    margin-bottom: 30px;
`;

const Header1 = styled.h1`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Text = styled.p`
    margin-bottom: 100px;
`;

const H3 = styled.h3`
    margin-bottom: 1px;
`;

const H3s = styled.h3`
    margin-bottom: 1px;
    background-color: #44e36f;
`;


const InhaltBox = styled.div`
    width: 80%;
    margin-bottom: 50px;
`;

const AlignBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const AlignBoxItem = styled.div`
    // border: 1px solid red;
    margin-right: 10px;
`;

const HR = styled.hr`
    opacity: 0.1;
    margin: 20px 0;
`;


function ImmoPageSieben() {
    return(
        <>
        <Content>
            <HeadBox>
                <Image src={tirol} />
                <DataBox>
                    <BlockBox>
                        <H3s>1.290.000 € €</H3s>
                        <p>Kaufpreis</p>
                    </BlockBox>

                    {/* <BlockBox>
                        <H3>740 m²</H3>
                        <p>Fläche</p>
                    </BlockBox> */}
                    <BlockBox>
                        <H3>5</H3>
                        <p>Zimmer</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>146 m²</H3>
                        <p>Wohnfläche</p>
                    </BlockBox>
                    <BlockBox>
                        <H3>201 m² m²</H3>
                        <p>Grundstück</p>
                    </BlockBox>
                </DataBox>  
            </HeadBox>
            <InhaltBox>
                <Header1>Doppelhaushälfte mit spektakulärer Dachterrasse in Tirol, Österreich</Header1>

                
                <AlignBox>
                    <AlignBoxItem>
                        <p>Typ:</p>
                        {/* <p>Etage:</p> */}
                        {/* <p>Wohnfläche ca:</p> */}
                        <p>Bezugsfrei ab:</p>
                        <p>Zimmer:</p>
                        {/* <p>Grundstück ca.:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        <p>Doppelhaushälfte</p>
                        {/* <p>0 von 4</p> */}
                        {/* <p>63 m²</p> */}
                        <p>sofort</p>
                        <p>1,5</p>
                        {/* <p>sofort</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>Zimmer:</p> */}
                        {/* <p>Schlafzimmer:</p>
                        <p>Badezimmer:</p> */}
                        <p>Garage/ Stellplatz:</p>
                        {/* <p>Internet:</p> */}
                    </AlignBoxItem>
                    <AlignBoxItem>
                        {/* <p>1</p>
                        <p>2</p> */}
                        <p>3 Stellplätze</p>
                        {/* <p>2 Stellplätze</p> */}
                        {/* <p>2 Garagen</p> */}
                    </AlignBoxItem>
                </AlignBox>
                <HR />
                <h2>Bausubstanz</h2>
                <AlignBox>
                    <AlignBoxItem>
                        <p>Zustand: gepflegt</p>
                        <p>teilweise unterkellert</p>
                        <p>3 Stellplätze: Carport, Außen-Stellplatz</p>
                        <p>Einbauküche</p>
                        <p>Böden: Holzdielen, Fliesenboden</p>
                        <p>Weitere Räume: Wasch-Trockenraum</p>
                        <p>barrierefrei, als Ferienimmobilie geeignet</p>
                        <p>Ausblick: Bergblick, Fernblick, Seeblick</p>
                    </AlignBoxItem>
                </AlignBox>



                
                <HR />
                <h2>Objektbeschreibung</h2>
                <p>Pfiffige Doppelhaushälfte in sehr ruhiger, idyllischer Lage direkt an einem kleinen See am Rande einer kleinen Ortschaft. Im Erdgeschoß befindet sich ein großes Wohnzimmer mit einem Kamin für einen Holzofenanschluss und eine moderne Wohnküche mit Essbereich für die große Familie. Außerdem gibt es hier noch eine Gästetoilette. 4 Schlafzimmer befinden sich im Obergeschoß sowie ein Badezimmer mit Dusche und Badewanne und eine hiervon getrennte Toilette. Im Dachgeschoß gelangt man auf die imposante Dachterrasse mit überdachtem Sitzbereich sowie wunderschöner Sonnenterrasse. Das Haus ist komplett unterkellert. Hier befinden sich 2 unterschiedlich große Räume für Hobby und Lagerfläche sowie ein Heizungs- und ein Tankraum. Zu dem Haus gehört ausserdem ein Carport sowie 2-3 Stellplätze und ein Gartenhäuschen.<br/><br/>

DIE BALKON-, TERRASSEN-, UND DACHTERRASSENFLÄCHE BETRÄGT INSGESAMT 90 QM.<br/><br/>

In kurzer Entfernung liegt der Patscher Kofel mit Skilift und besten Wandermöglichkeiten..<br/>
Es ist das perfekte FERIENHAUS in jeder Jahreszeit!<br/><br/>

Raumaufteilung<br/>
Das kleine Tiroler Dorf liegt in einer Gegend mit besonders hoher Lebensqualität und hohem Freizeit- und Erholungswert. Die wunderschöne Natur sowie die weite Sicht mit Berg- und Seeblick laden viele Gäste zu kleinen Ausflügen in die nähere Umgebung ein. In allen Himmelsrichtungen gibt es viele grüne Wiesen, Berge, Wald, den kleinen See und reine Natur mit himmlischen Wanderwegen..<br/>
Für Naturliebhaber, die eine traumhafte, idyllische, ruhige und entspannte Atmosphäre schätzen, ist die Gegend extrem reizvoll, aber dennoch liegt das Haus äußerst verkehrsgünstig. Man ist in nur wenigen Minuten in Insbruck.<br/><br/>

Von der Wohnung haben Sie die Möglichkeit weiter nach St. Moritz oder nach Italien reisen.</p>
            </InhaltBox>
        </Content>
        </>
    )
}

export default ImmoPageSieben;